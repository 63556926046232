
import Wave from 'react-wavify'
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';

// Add the solid (fas) icons to the library
library.add(fas);

export default function Footer() {

  return (

    <div className=''> 


<p className='static text-gray  text-xs text-center items-center justify-between '  >Website Create by <a href="https://rootgroup.tech/" target="_blank" className='text-black font-bold '>Root Group</a></p>




      <Wave mask="url(#mask)" fill="#de5529" >
        
  <defs>
    
    <linearGradient id="gradient" gradientTransform="rotate(20)">
      <stop offset="0" stopColor="white" />
      
      <stop offset="0.7" stopColor="black" />

    </linearGradient>
    
    <mask id="mask">
      <rect x="0" y="0" width="4000" height="200" fill="url(#gradient)"  />
    </mask>
  </defs>
</Wave>
</div>
  )
}



