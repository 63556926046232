
export default function Example() {
  return (
    <div className="relative bg-orangeproject">
      <div className="h-56 bg-orangeproject sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
        <img
          className="w-full h-full object-cover"
          src="https://firebasestorage.googleapis.com/v0/b/nord-wolf-polymer-products.appspot.com/o/Cover%2Fc.jpg?alt=media&token=7adbcbf7-e3c0-4b68-bbfb-2120fa5e9f48"
          alt=""
        />
      </div>
      <div className="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
        <div className="md:ml-auto md:w-1/2 md:pl-10">
          <h2 className="text-base font-semibold uppercase tracking-wider text-white">Nord Wolf Polymer</h2>
          <p className="mt-2 text-white text-3xl font-extrabold tracking-tight sm:text-4xl">OUR SERVICES</p>
          <p className="mt-3 text-lg text-white">
          We offer you, as our valued customers, raw material supply upon your request. It is our primary duty to deliver your needed all our products in optimal conditions and at the earliest. Please do not hesitate to consult us for your new product quests and ask for help for any questions including materials that are not on our product list. It is a pleasure for us to serve for you.

          </p>
          <div className="mt-8">
            <div className="inline-flex rounded-md shadow">
              <a
                href="#"
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-900 bg-white hover:bg-gray-50"
              >
                Order Now
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
