import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Import carousel styles
import "../css/slider.css"
const CarouselComponent = () => {
  return (
    <Carousel
      showArrows={true}
      infiniteLoop={true}
      showThumbs={false}
      showStatus={false}
      autoPlay={true}
      interval={3000} 
      className='mt-2'
      // Set the interval for auto sliding
    >

     
      <div>
      <img src="https://firebasestorage.googleapis.com/v0/b/nord-wolf-polymer-products.appspot.com/o/Cover%2Fd.jpg?alt=media&token=bce8b5f0-c296-4420-aad4-da94d3164bee" alt="Image 1" />
      </div>
      <div>
      <img src="https://firebasestorage.googleapis.com/v0/b/nord-wolf-polymer-products.appspot.com/o/Cover%2Fimage-asset.jpeg?alt=media&token=8d7d48bf-09d1-40e4-a7e8-ee4e3e1a26e1" alt="Image 1" />
      </div>
      <div>
      <img src="https://firebasestorage.googleapis.com/v0/b/nord-wolf-polymer-products.appspot.com/o/Cover%2Fpoly.jpg?alt=media&token=bf82076a-76df-4676-92ba-d2ec209c0e161" alt="Image 1" />
      </div>
      <div>
      <img src="https://firebasestorage.googleapis.com/v0/b/nord-wolf-polymer-products.appspot.com/o/Cover%2Fpolymar.jpg?alt=media&token=7f623afd-2f45-4389-9065-73638ed8d6e7" alt="Image 1" />
      </div>
      <div>
      <img src="https://firebasestorage.googleapis.com/v0/b/nord-wolf-polymer-products.appspot.com/o/Cover%2Fimage-asset.jpeg?alt=media&token=8d7d48bf-09d1-40e4-a7e8-ee4e3e1a26e1" alt="Image 1" />
      </div>
    </Carousel>
  );
};

export default CarouselComponent;
