import { ArrowPathIcon, CloudArrowUpIcon, FingerPrintIcon, LockClosedIcon } from '@heroicons/react/24/outline'


export default function Example() {
  return (
    <div className="bg-white py-24 sm:py-32 ">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-orangeproject">Nord Wolf Polymer</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Nord Wolf Polymer Corproprate
          
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600 text-justify">
          Our firm Nord Wolf Polymer is specialized in Chemical and Plastic Raw Material import and sales activities. From that day onwards, we have been supplying raw material under optimal conditions with quality and rich range of products for our valuable customers from all over the world.
Our Firm Nord Wolf Polymer, which is specialized in the products produced all over the world, has become one of the leading suppliers today thanks to its strong structure and experience. Moreover, with its strategic management mentality, it has obtained the authority to perform direct imports from the business associates having the same reliability.
The raw materials that we frequently import are PP, PE, PVC. As Nord Wolf Polymer , our firm has gained a reputable place in the market. As is now, our firm will also continue its efforts with the same discipline in order to contribute to the sector and to our manufacturers in the future.
          </p>
        </div>
        {/* <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-16">
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-orangeproject">
                    <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-600">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div> */}
      </div>
    </div>
  )
}
