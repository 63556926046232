
import Navbar from './Navbar';
import ProductHome from './ProductHome'
import Carporate from "./Corporate"

function App() {
  return (
    <div class="relative w-full h-96">
    <iframe class="absolute top-0 left-0 w-full h-full"
 src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d103859.6538381665!2d45.2950827137182!3d35.56322964705835!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40002c0536d143b1%3A0xf791750d4e215dea!2sSulaymaniyah!5e0!3m2!1sen!2siq!4v1698005728611!5m2!1sen!2siq" 
        frameborder="0"  allowfullscreen="" aria-hidden="false" tabindex="0">
    </iframe>
</div>
  );
}

export default App;
