import { useState } from 'react'
import React, { useRef } from 'react';

import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import logo from '.././logo.svg'
import Slider from './slider.js'
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';
import ProductHome from './ProductHome'
import Carporate from "./Corporate"
import Services from "./Services"
import Contact from "./Contact"
import About from "./About"
import { faFacebookF } from '@fortawesome/free-brands-svg-icons'; // Import the Facebook icon from the brands package
import {faInstagram } from '@fortawesome/free-brands-svg-icons'; // Import the Instagram icon from the brands package
import { Link, animateScroll as scroll } from "react-scroll";
library.add(faCoffee, faInstagram);


const navigation = [
  { name: 'Home', href: '#' },
  { name: 'Corporate', href: '#' },
  { name: 'Products', href: '#' },
  { name: 'Services', href: '#' }, 
   { name: 'Contact us', href: '#' },
]

export default function Example() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const elementsToScroll = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  function pathing(x){
    setMobileMenuOpen(false);
    scrollToElement(x)
  }
  const scrollToElement = (index) => {
    elementsToScroll[index].current.scrollIntoView({ behavior: 'smooth' });
  };
  return (
    <div className="bg-white ">
      <header className="fixed inset-x-0 top-0 z-50 bg-white ">
        <nav className="flex items-center justify-between p-6 lg:px-8  " aria-label="Global"  >
          <div className="flex lg:flex-1">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img
                className="h-16 w-auto"
                src={logo}
                alt=""
              />
            </a>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">

         
           
              <a  onClick={() => scrollToElement(0)} 
              className="text-sm font-semibold leading-6 text-gray-900 hover:text-orangeproject">
              Home
              </a>

              

              <a  onClick={() => scrollToElement(2)}  className="text-sm font-semibold leading-6 text-gray-900 hover:text-orangeproject">
              Products 
              </a>
              <a  onClick={() => scrollToElement(1)}  className="text-sm font-semibold leading-6 text-gray-900 hover:text-orangeproject">
              Corporate
              </a>
              <a  onClick={() => scrollToElement(3)}  className="text-sm font-semibold leading-6 text-gray-900 hover:text-orangeproject">
             Services
              </a>
              <a  onClick={() => scrollToElement(4)}  className="text-sm font-semibold leading-6 text-gray-900 hover:text-orangeproject">
              Contact us
              </a>

              <a  onClick={() => scrollToElement(5)}  className="text-sm font-semibold leading-6 text-gray-900 hover:text-orangeproject">
              About us
              </a>
         
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            <a href="#" className="text-sm font-semibold leading-6 text-gray-900">
      
            <FontAwesomeIcon icon={faFacebookF} />
                        </a>
                        
                        <a href="#" className=" ml-4 text-sm font-semibold leading-6 text-gray-900">
      
      <FontAwesomeIcon icon={faInstagram} />
                  </a>
          </div>
        </nav>
        <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
          <div className="fixed inset-0 z-50" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <img
                  className=" h-14 w-auto"
                  src={logo}
                  alt=""
                />
              </a>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
              
                   
               

                    <a  onClick={() => pathing(0)}                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-orangeproject"
>
              Home
              </a>

             

              <a  onClick={() =>  pathing(2)}                       className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-orangeproject"
>
              Products 
              </a>

              <a  onClick={() =>  pathing(1)}                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-orangeproject"
>
              Corporate
              </a>

              <a  onClick={() =>  pathing(3)}                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-orangeproject"
>
             Services
              </a>
              <a  onClick={() =>  pathing(4)}                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-orangeproject"
>
              Contact us
              </a>
              <a  onClick={() =>  pathing(5)}                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-orangeproject"
>
             About us
              </a>

                </div>
              
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>

      <div className="relative isolate px-6 pt-14 lg:px-8">
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(70%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#fea689] to-[#de5529] opacity-20 sm:left-[calc(70%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
        <div className=" py-12" ref={elementsToScroll[0]}>
        <Slider />
    
<div id='title' >

        <h1  class="animate__animated animate__backInRight   ">Nord Wolf Polymer Products LTD
</h1>
<a onClick={() => scrollToElement(2)}   class="animate__animated  animate__backInDown mt-3  px-6 py-4 text-xs font-medium text-center text-white bg-orangeproject rounded-lg hover:bg-orangeproject-100 focus:ring-4 focus:outline-none">
Our Products <span aria-hidden="true">&rarr;</span>
            </a>
<button type="button" ></button>


        </div>

        </div>
        <div
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
      </div>
      <div ref={elementsToScroll[2]} >
      <ProductHome />
      </div>
      <div ref={elementsToScroll[1]} >
      <Carporate />
            </div>
      
      <div ref={elementsToScroll[3]} >
      <Services /> </div>

      <div ref={elementsToScroll[4]} >
      <Contact />
      </div>

      <div ref={elementsToScroll[5]} >
      <About />
      </div>
     
     

    </div>
  )
}
