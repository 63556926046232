import React, { useRef } from 'react';

import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import logo from '.././logo.svg'
import Slider from './slider.js'
import { useState, useEffect } from "react";
import { getDatabase, ref as databaseRef, child, get } from "firebase/database";
import { useParams } from 'react-router-dom';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';
import ProductHome from './ProductHome'
import Carporate from "./Corporate"
import Services from "./Services"
import Contact from "./Contact"
import { faFacebookF } from '@fortawesome/free-brands-svg-icons'; // Import the Facebook icon from the brands package
import {faInstagram } from '@fortawesome/free-brands-svg-icons'; // Import the Instagram icon from the brands package
import { Link, animateScroll as scroll } from "react-scroll";
library.add(faCoffee, faInstagram);


const navigation = [
  { name: 'Home', href: '#' },
  { name: 'Corporate', href: '#' },
  { name: 'Products', href: '#' },
  { name: 'Services', href: '#' }, 
   { name: 'Contact us', href: '#' },
   { name: 'About us', href: '#' },
]

export default function Example() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const elementsToScroll = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  const scrollToElement = (index) => {
    elementsToScroll[index].current.scrollIntoView({ behavior: 'smooth' });
  };

  let [data, setData] = useState();

    const [products, setproducts] = useState([]);
    let id  = useParams();
    
    useEffect(() => {
      
        const dbRef = databaseRef(getDatabase());
        get(child(dbRef, "Products/")).then((snapshot) => {
          {setproducts("")}
          if (snapshot.exists()) {
                 setData(snapshot.val());
                
            snapshot.forEach(snap => {
                // snap.val() is the dictionary with all your keys/values from the 'students-list' path
              
                if(snap.val().id==id.id  ){
                  setproducts(arr => [...arr, snap.val()]); 
    
                } 
             
               
              
            });
        
          }
        
           
         
        }).catch((error) => {
          console.error(error);
        });
    
        // Don't forget to detach the event listener when the component unmounts
  
      }, []);
  
      if(products!=[]){
        products.sort((a, b) => (a.createat>b.createat) ? 1 : -1);
      }

      

  return (
    <div className="bg-white ">
     
      <header className="fixed inset-x-0 top-0 z-50 bg-white ">
        <nav className="flex items-center justify-between p-6 lg:px-8  " aria-label="Global"  >
          <div className="flex lg:flex-1">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img
                className="h-16 w-auto"
                src={logo}
                alt=""
              />
            </a>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">

         
           
              <a  href="/" 
              className="text-sm font-semibold leading-6 text-gray-900 hover:text-orangeproject">
              Home
              </a>

              

              <a  href="/"  className="text-sm font-semibold leading-6 text-gray-900 hover:text-orangeproject">
              Products 
              </a>
              <a  href="/"  className="text-sm font-semibold leading-6 text-gray-900 hover:text-orangeproject">
              Corporate
              </a>
              <a  href="/"  className="text-sm font-semibold leading-6 text-gray-900 hover:text-orangeproject">
             Services
              </a>
              <a  href="/"  className="text-sm font-semibold leading-6 text-gray-900 hover:text-orangeproject">
              Contact us
              </a>
              <a  href="/"  className="text-sm font-semibold leading-6 text-gray-900 hover:text-orangeproject">
              About us
              </a>
         
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            <a href="#" className="text-sm font-semibold leading-6 text-gray-900">
      
            <FontAwesomeIcon icon={faFacebookF} />
                        </a>
                        
                        <a href="#" className=" ml-4 text-sm font-semibold leading-6 text-gray-900">
      
      <FontAwesomeIcon icon={faInstagram} />
                  </a>
          </div>
        </nav>
        <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
          <div className="fixed inset-0 z-50" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <img
                  className="h-8 w-auto"
                  src={logo}
                  alt=""
                />
              </a>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
              
                   
               

                    <a  href="/"                       className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-orangeproject"
>
              Home 
              </a>

             

              <a  href="/"                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-orangeproject"
>
              Products 
              </a>

              <a  href="/"                   className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-orangeproject"
>
              Corporate
              </a>

              <a  href="/"                     className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-orangeproject"
>
             Services
              </a>
              <a  href="/"                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-orangeproject"
>
              Contact us
              </a>

              <a  href="/"                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-orangeproject"
>
              About us
              </a>
                </div>
               
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>

      <div className="relative isolate px-6 pt-14 lg:px-8">
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(70%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#fea689] to-[#de5529] opacity-20 sm:left-[calc(70%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>

<div className=' mt-52 mb-56 ml-4 flex justify-center items-center '>

{Array.isArray(products) ? products.map((applicant,index) =>{

return(
  <div key={index } class=" hover:scale-110 max-w-sm rounded overflow-hidden shadow-lg">
  <img class="w-full" src={applicant.imageUrls_food} alt="Sunset in the mountains"/>
  <p class="text-gray-700 text-base mt-2== font-bold">
    {applicant.name_food}
    </p>
  <div class="px-6  	">
    <div class="  mb-2  text-left">{applicant.info_food}</div>
   
  </div>
 
</div>
)
}
): null}
  </div>    

 
      </div>
   

    </div>
  )
}
