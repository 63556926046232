

import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
  apiKey: "AIzaSyA3g4loSN56kKtPk2ftXFq8tV4gKb6aN3o",
  authDomain: "nord-wolf-polymer-products.firebaseapp.com",
  databaseURL: "https://nord-wolf-polymer-products-default-rtdb.firebaseio.com",
  projectId: "nord-wolf-polymer-products",
  storageBucket: "nord-wolf-polymer-products.appspot.com",
  messagingSenderId: "831103268434",
  appId: "1:831103268434:web:fa2b8ec5a1fd333566518b"
};

const firebaseApp = initializeApp(firebaseConfig);
const database = getDatabase(firebaseApp);

// Now you can use 'firebaseApp' and 'database' in your code.
