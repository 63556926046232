import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { useState } from 'react'
import firebase from '../firebase';
import { getDatabase, ref, set } from "firebase/database";
import emailjs from 'emailjs-com';
library.add(faCoffee, faPhone,faEnvelope,faGlobe);


export default function Example() {
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    subject: '',
    message: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs.send('service_ph9iezf', 'template_6qj29or', formData, 'k3V9ERj7CJSXvu_6O')
      .then((result) => {
        console.log(result.text);
        alert('Message sent successfully!');
      }, (error) => {
        console.log(error.text);
        alert('Failed to send message, please try again later.');
      });
  };

  return (
    <div className="bg-gray-100">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div className="relative bg-white rounded-md">
          <h2 className="sr-only">Contact us</h2>

          <form onSubmit={handleSubmit} className="grid grid-cols-1 lg:grid-cols-3">
            <div className="relative overflow-hidden py-10 px-6 bg-orangeproject sm:px-10 xl:p-12 rounded-tl-lg rounded-bl-lg">
              {/* Contact information */}
              <h3 className="text-lg font-medium text-white">Nord Wolf Polymer Products LTD</h3>
              <p className="mt-6 text-base text-indigo-50 max-w-3xl">
                New Dollar trading building
                Floor 5, office 110, Khanaqa Sulaymaniyah, Iraq
              </p>
              <dl className="mt-8 space-y-6">
                <dd className="flex text-base text-indigo-50">
                  <FontAwesomeIcon icon={faPhone} className="flex-shrink-0 w-6 h-6 text-white" aria-hidden="true" />
                  <span className="ml-3">+ (964) 770 070 0005</span>
                </dd>
                <dd className="flex text-base text-indigo-50">
                  <FontAwesomeIcon icon={faEnvelope} className="flex-shrink-0 w-6 h-6 text-white" aria-hidden="true" />
                  <span className="ml-3">Info@nordwolf.co</span>
                </dd>
                <dd className="flex text-base text-indigo-50">
                  <FontAwesomeIcon icon={faGlobe} className="flex-shrink-0 w-6 h-6 text-white" aria-hidden="true" />
                  <span className="ml-3">https://nordwolf.co/</span>
                </dd>
              </dl>
            </div>

            {/* Contact form */}
            <div className="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12">
              <h3 className="text-lg font-medium text-gray-900">Send us a message</h3>
              <div className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
                <div>
                  <label htmlFor="first_name" className="block text-left text-sm font-medium text-gray-900">
                    First name
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="first_name"
                      id="first_name"
                      value={formData.first_name}
                      onChange={handleChange}
                      required
                      autoComplete="given-name"
                      className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-orangeproject focus:border-orangeproject border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor="last_name" className="block text-left text-sm font-medium text-gray-900">
                    Last name
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="last_name"
                      id="last_name"
                      value={formData.last_name}
                      onChange={handleChange}
                      required
                      autoComplete="family-name"
                      className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-orangeproject focus:border-orangeproject border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor="email" className="block text-left text-sm font-medium text-gray-900">
                    Email
                  </label>
                  <div className="mt-1">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                      autoComplete="email"
                      className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-orangeproject focus:border-orangeproject border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div>
                  <div className="flex justify-between">
                    <label htmlFor="phone" className="block text-sm font-medium text-gray-900">
                      Phone
                    </label>
                  </div>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="phone"
                      id="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      required
                      autoComplete="tel"
                      className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-orangeproject focus:border-orangeproject border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label htmlFor="subject" className="text-left block text-sm font-medium text-gray-900">
                    Subject
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="subject"
                      value={formData.subject}
                      onChange={handleChange}
                      required
                      id="subject"
                      className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-orangeproject focus:border-orangeproject border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <div className="flex justify-between">
                    <label htmlFor="message" className="block text-sm font-medium text-gray-900">
                      Message
                    </label>
                    <span id="message-max" className="text-sm text-gray-500">
                      Max. 500 characters
                    </span>
                  </div>
                  <div className="mt-1">
                    <textarea
                      id="message"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      required
                      rows={4}
                      className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-orangeproject focus:border-orangeproject border-gray-300 rounded-md"
                      aria-describedby="message-max"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2 sm:flex sm:justify-end">
                  <button
                    type='submit'
                    className="mt-2 w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-orangeproject hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orangeproject sm:w-auto"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
