
import Navbar from './Navbar';
import ProductHome from './ProductHome'
import Carporate from "./Corporate"
import Services from "./Services"
import Contact from "./Contact"
import Mapp from "./Mapp"
import Footers from "./Footer"
import Wave from "./Wave"
import NotFound from './NotFound';


import Showproduct from "./showproduct"
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

function App() {

  
  return (
    <div className="App">


        <title>Nord Wolf Polymer </title>
        <meta name="description" content="Discover Nord Wolf Polymer, the industry-leading material known for its strength, durability, and versatility in various applications." />
        <meta name="keywords" content="Nord Wolf Polymer, high-performance polymer, durable materials, advanced polymers" />
        <link rel="canonical" href="https://nordwolf.co/" />

        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@type": "Product",
            "name": "Nord Wolf Polymer",
            "description": "Nord Wolf Polymer is a high-performance material known for its durability and versatility.",
            "brand": {
              "@type": "Brand",
              "name": "Nord Wolf"
            },
            "offers": {
              "@type": "Offer",
              "url": "https://www.yourwebsite.com/nord-wolf-polymer",
              "priceCurrency": "USD",
              "price": "99.99",
              "itemCondition": "https://schema.org/NewCondition",
              "availability": "https://schema.org/InStock"
            }
          }
          `}
        </script>
  
      
 <Router>
      <Switch>
        <Route path="/" exact component={Navbar}/>

        
       
    

        
        <Route path="/showproduct/:id" exact component={Showproduct}/>
        <Route path="*" exact component={NotFound}/>

      </Switch>
    </Router>
      
    
{/* <ProductHome/>
<Carporate/>
<Services/>
<Contact/> */}
<Mapp/>
<Footers/>
<Wave/>
    </div>
  );
}

export default App;
