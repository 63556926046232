import logo from './logo.svg';
import './App.css';
import Home from './Component/Home'
import 'animate.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import {Helmet} from "react-helmet";
function App() {
  return (
    <div className="App">
 
  

   <Home/>

   <Helmet><title>Nord Wolf Polymer - High-Performance Materials</title>
        <meta name="description" content="Discover Nord Wolf Polymer, the industry-leading material known for its strength, durability, and versatility in various applications." />
        <meta name="keywords" content="Nord Wolf Polymer, high-performance polymer, durable materials, advanced polymers" />
        <link rel="canonical" href="https://www.yourwebsite.com/nord-wolf-polymer" />

        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@type": "Product",
            "name": "Nord Wolf Polymer",
            "description": "Nord Wolf Polymer is a high-performance material known for its durability and versatility.",
            "brand": {
              "@type": "Brand",
              "name": "Nord Wolf"
            },
            "offers": {
              "@type": "Offer",
              "url": "https://www.yourwebsite.com/nord-wolf-polymer",
              "priceCurrency": "USD",
              "price": "99.99",
              "itemCondition": "https://schema.org/NewCondition",
              "availability": "https://schema.org/InStock"
            }
          }
          `}
        </script>    </Helmet>

    </div>
  );
}

export default App;
