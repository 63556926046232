/* This example requires Tailwind CSS v2.0+ */
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useState, useEffect } from "react";
import { getDatabase, ref as databaseRef, child, get } from "firebase/database";

library.add(faCoffee, faArrowRight);





  export default function Example() {
    let [data, setData] = useState();

    const [products, setproducts] = useState([]);

    useEffect(() => {
        const dbRef = databaseRef(getDatabase());
        get(child(dbRef, "Products/")).then((snapshot) => {
          {setproducts("")}
          if (snapshot.exists()) {
                 setData(snapshot.val());
                
            snapshot.forEach(snap => {
                // snap.val() is the dictionary with all your keys/values from the 'students-list' path
              
                setproducts(arr => [...arr, snap.val()]); 
               
              
            });
        
          }
        
           
         
        }).catch((error) => {
          console.error(error);
        });
    
        // Don't forget to detach the event listener when the component unmounts
  
      }, []);
  
      if(products!=[]){
        products.sort((a, b) => (a.createat>b.createat) ? 1 : -1);
      }

      
    return (
      <div className="bg-white">


        <div className="max-w-2xl mx-auto py-16 px-4 sm:py-14 sm:px-6 lg:max-w-7xl lg:px-8 ">
          <h2 className="text-xl font-bold text-gray-900"> Products</h2>
  
          <div className="card mt-8 grid grid-cols-1 gap-y-12 sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8   ">
            {products.map((product) => (
              <div key={product.id} >
                <div  >
                  <div className="relative w-full h-72 rounded-lg overflow-hidden   ">
             
<div class="container relative w-full h-72 rounded-lg overflow-hidden" >
  <img src={product.imageUrls_food
} alt="Avatar" class="image"     className="w-full h-full object-center object-cover"/>
  <div class="overlay">
    <div class="text" className='line-clamp-3 mt-2 text-white'>{product.info_food}</div>
  </div>
</div>
                  </div>
                 
                </div>
                <div className=" mt-4">
                  <h1 className=' font-bold float-left'>{product.name_food}</h1>
                  <br/>
                  </div>
                <div className=" mt-4">
                  <a
                    href={"/showproduct/"+product.id}
                    className="hover:-translate-y-1 hover:scale-110  duration-300 relative flex bg-gray-100 border border-transparent rounded-md py-2 px-8 items-center justify-center text-sm font-medium text-gray-900 hover:bg-gray-200"
                    
                 >
                    <span>See More</span>  <FontAwesomeIcon  className=" ml-2" icon={faArrowRight} />
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
  